import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import Link from '@/components/atoms/link';
import Text from '@/components/atoms/text';

const Miniature = ({ miniature, className, clientId = null, ...props }) => {
	let linker = `/quote/${miniature.uri}`;
	if (clientId) {
		linker = `/quote/${miniature.uri}?client_id=${clientId}`;
	}
	return (
		<Link
			href={linker}
			className={cn(
				'flex items-center transition-all bg-white rounded-lg hover:shadow-drop-shadow h-eis-72 w-full sm:w-[calc(50%-1.5rem)] md:w-[calc(33.33%-1.5rem)] xl:w-[calc(25%-1.5rem)] sm:mx-3',
				className && className
			)}
		>
			<div className="relative shrink-0">
				<img src={miniature.imgSrc} alt={miniature.name} className="block object-cover rounded-l-lg aspect-114/72 w-eis-114" />

				<div className={cn('absolute bottom-0 left-0 w-full', miniature.themeColor && `text-${miniature.themeColor}`)}>
					<svg viewBox="0 0 114 72" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M114,0C114,92.1,4.3,72,0,56.7c0,0,0,3.5,0,7.1C0,68.5,2.9,72,7.8,72S114,72,114,72V0z" fill="currentColor" />
					</svg>
				</div>
			</div>

			<div className="flex flex-col w-full gap-1 px-4 py-3">
				<Text type="p" variant="bold" className="text-eis-black">
					{miniature.name}
				</Text>
			</div>
		</Link>
	);
};

export default Miniature;

Miniature.propTypes = {
	miniature: PropTypes.object,
	clientId: PropTypes.number,
};
Miniature.defaultProps = {
	miniature: null,
	clientId: null,
};
